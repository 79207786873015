import { TonClient, beginCell, Address } from "@ton/ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";

const TON_END_POINT = 'https://toncenter.com/api/v2/jsonRPC';
const TON_API_KEY = 'e5479c4553ed5bcca752295a60b8b0af47ad9a51644699d0429035481a687bf0';

export async function getJettonWalletAddress(client, ownerAddress, jettonMasterAddress) {
  const result = await client.runMethod(
    Address.parse(jettonMasterAddress),
    "get_wallet_address",
    [{
      type: "slice",
      cell: beginCell()
        .storeAddress(Address.parse(ownerAddress))
        .endCell(),
    }]
  );

  return result.stack.readAddress().toString();
}

export async function initializeClient(network) {
  // const endpoint = await getHttpEndpoint({ network });
  // return new TonClient({ endpoint });

  return new TonClient({
    endpoint: TON_END_POINT,
    apiKey: TON_API_KEY
  });

}
