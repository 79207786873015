import { createContext, useEffect, useState, useMemo } from "react";
import {
  Routes,
  Route
} from "react-router-dom";

import axios from "axios";
import { API_BASE_URL } from "./config";

import Home from "./pages/Home";
import Friends from "./pages/Friends";
import Tasks from "./pages/Tasks";
import Misc from "./pages/Misc";
import LoadingDialog from "./components/LoadingDialog"
import "./index.css";

export const AppContext = createContext(null);

export default function App() {
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [loadingPrompt, setLoadingPrompt] = useState("");
  const [openLoading, setOpenLoading] = useState(false);

  const getAuthandUserInfo = async (userInfo) => {
    console.log("---------------UserID: ", userInfo.id);
    try {
      const chatId = userInfo.id;
      if (chatId == null || chatId == undefined) {
        return;
      }
      const { data } = await axios.post(
        `${API_BASE_URL}/auth/getAuthentication`,
        {
          webAppUserInfo: userInfo
        }
      );
      console.log(data);
      if (data.token) {
        localStorage.setItem("authorization", data.token);
        setToken(token);
      }
      const { data: userinfo } = await axios.get(`${API_BASE_URL}/user/info`, {
        headers: {
          Authorization: `bearer ${data.token}`
        }
      });
      console.log("[Userinfo]", userinfo);
      if (userinfo.state) setUserInfo(userinfo.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("-----init data-----", window.Telegram.WebApp.initData);
    if (
      window.Telegram.WebApp.initData == null ||
      window.Telegram.WebApp.initData == undefined
    ) {
      // Error Handle
      console.log(
        "Telegram WebApp InitData is not existed. Please use telegram app."
      );
    } else {
      const params = new URLSearchParams(window.Telegram.WebApp.initData);
      const user = params.get("user");
      console.log("USER: ", user);
      if (user) getAuthandUserInfo(JSON.parse(decodeURIComponent(user)));
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        userInfo,
        setUserInfo,
        setOpenLoading,
        setLoadingPrompt
      }}
    >
      <LoadingDialog isOpen={openLoading} prompt={loadingPrompt} />
      <Routes>
        <Route>
          <Route path="/" Component={Home} />
          <Route path="/tasks" Component={Tasks} />
          <Route path="/friends" Component={Friends} />
          <Route path="/wallet/:state" Component={Misc} />
        </Route>
        <Route path="*" Component={Home} />
      </Routes>
    </AppContext.Provider>
  );
}
