import { useContext, useEffect, useState } from "react";
import { Button, Card, user } from "@nextui-org/react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import { AppContext } from "../App";

import { ReactComponent as DollarFillIcon } from "../assets/icons/dollar-fill.svg";
import { ReactComponent as RightLineIcon } from "../assets/icons/right-line.svg";
import { ReactComponent as CheckLineIcon } from "../assets/icons/check-fill.svg";

const TaskCard = ({ taskId, title, logo, reward, url, isJoined, setScore }) => {
  const { userInfo, setUserInfo } = useContext(AppContext);
  // const [isJoined, setJoined] = useState(false);

  const checkTaskClaimed = (taskId) => {
    let i = 0;
    console.log("task : ", userInfo.tasks)
    for (i = 0; i < userInfo.tasks.length; i++) {
      const task = userInfo.tasks[i];
      if (task.taskId == taskId) {
        return true;
      }
    }
    return false;
  }

  const onItemClicked = async () => {
    if (!checkTaskClaimed(taskId)) {
      const token = localStorage.getItem("authorization");
      const { data } = await axios.post(`${API_BASE_URL}/task/task-claimed`,
        { chatId: userInfo.chatId, taskId: taskId },
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
      setUserInfo(data.data);
      setScore(reward);
    }

    if (url)
      window.location.href = url;
  }

  return (
    <Card className="relative flex-row items-center w-full gap-2 p-2 text-left shadow-none rounded-2xl bg-background-light">
      <img src={logo} className="rounded-lg w-11 h-11" />
      <div className="space-y-1 text-base grow">
        <h5 className="text-green-400 ">{title}</h5>
        <div className="flex items-center gap-2 text-warning">
          <DollarFillIcon className="w-7 h-7" />
          <p className="text-green-400 ">
            {isJoined == false ? reward : "already received reward"}
          </p>
          <p className="font-bold text-green-400 ">
            {isJoined == false ? "world" : ""}
          </p>
        </div>
      </div>
      {isJoined == false ? (
        <Button isIconOnly variant="light" radius="full" onClick={onItemClicked}>
          <RightLineIcon className="text-white w-7 h-7" />
        </Button>
      ) : (
        <Button isIconOnly variant="light" radius="full" disableRipple="true">
          <CheckLineIcon className="w-6 h-6 text-white" />
        </Button>
      )}
    </Card>
  );
}

TaskCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  reward: PropTypes.number,
  isUser: PropTypes.string,
};

export default function Tasks() {
  const { userInfo, setUserInfo } = useContext(AppContext);
  const [tasksList, setTaskList] = useState([]);
  const [totalTaskBalance, setTotalTaskBalance] = useState(0);
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  console.log("userInfo >>>>", userInfo)

  const fetchData = async () => {
    const token = localStorage.getItem("authorization");
    const { data } = await axios.get(`${API_BASE_URL}/task/list`, {
      headers: {
        Authorization: `bearer ${token}`
      }
    });

    console.log("data : ", data);
    setTaskList(data.tasks);
    setUserInfo(data.data);

    let i = 0;
    console.log("userInfo.tasks", userInfo.tasks)
    let updateBalance = totalTaskBalance;
    for (i = 0; i < userInfo.tasks.length; i++) {
      const task = userInfo.tasks[i];
      if (!task.isClaimed) {
        console.log("tasksList[i] : ", data.tasks[i]);
        updateBalance += data.tasks[i].reward;
      }
      setTotalTaskBalance(updateBalance);
    }
  }

  useEffect(() => {
    console.log("task", userInfo)
    fetchData();

  }, []);

  const checkTaskJoined = (taskId) => {
    for (let i = 0; i < userInfo.tasks.length; i++) {
      const task = userInfo.tasks[i];
      if (task.taskId == taskId)
        return true;
    }
    return false;
  }

  useEffect(() => {
    console.log("totalTaskBalance", totalTaskBalance);
    console.log("score", score);
    if (score > 0)
      setTotalTaskBalance(totalTaskBalance + score);
  }, [score]);

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col h-fit gap-6 p-6 bg-black max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0 ">
      <div onClick={goHome} className="w-8 h-8 flex absolute right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
      <div className="pl-10">
        <img src="/images/mark.png" alt="Logo" className="w-32 h-32" />
      </div>
      <div className="flex flex-col items-center ml-8 mr-8 text-xl font-bold text-green-400">
        Complete tasks to earn world and contribute to the Prosperous community
      </div>
      <div className="inset-0 flex flex-row justify-center">
        <h1 className="mr-4 text-3xl font-bold text-center text-yellow-400">
          W{" "}
        </h1>
        <h1 className="text-3xl font-bold text-center text-yellow-400">
          {totalTaskBalance}
        </h1>
      </div>
      <div className="space-y-2 mt-2 px-4 overflow-y-scroll h-60" >
        {tasksList.map((item) => (
          <TaskCard {...item} isJoined={checkTaskJoined(item.taskId)} setScore={setScore} />
        ))}
      </div>
    </div>
  );
}
