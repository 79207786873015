import { Outlet } from "react-router-dom";

import BottomNavbar from "./BottomNavbar";

export default function Layout() {
  return (
    <main className="bg-black text-foreground">
      <Outlet />
      <BottomNavbar />
    </main>
  );
}
