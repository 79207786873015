import NavItem from "./NavItem";
const HomeLineIcon = "/images/home.png";
const HomeFillIcon = "/images/home.png";
const TaskLineIcon = "/images/tasks.PNG";
const TaskFillIcon = "/images/tasks.PNG";
const FriendsLineIcon = "/images/friends.PNG";
const FrinedsFillIcon = "/images/friends.PNG";
const WalletLineIcon = "/images/wallet.PNG";
const WalletFillIcon = "/images/wallet.PNG";

const navigation = [
  {
    name: "HOME",
    path: "/",
    icon: HomeLineIcon,
    activeIcon: HomeFillIcon
  },
  {
    name: "TASKS",
    path: "/tasks",
    icon: TaskLineIcon,
    activeIcon: TaskFillIcon
  },
  {
    name: "FRIENDS",
    path: "/friends",
    icon: FriendsLineIcon,
    activeIcon: FrinedsFillIcon
  },
  {
    name: "WALLET",
    path: "/wallet/0",
    icon: WalletLineIcon,
    activeIcon: WalletFillIcon
  }
];

export default function BottomNavbar() {
  return (
    <nav className="grid grid-cols-4">
      {navigation.map((item) => (
        <NavItem key={item.name} {...item} />
      ))}
    </nav>
  );
}
