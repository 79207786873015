import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import { AppContext } from "../App";
import { API_BASE_URL } from "../config";
import axios from "axios";

export default function Home() {
  const { userInfo, setUserInfo } = useContext(AppContext);
  const navigate = useNavigate(); // Hook for navigation

  const showStakingDlg = () => {
    console.log("showStakingDlg");
    navigate("/wallet/1");
  };

  const initLoading = async () => {
    try {
      const token = localStorage.getItem("authorization");
      const { data: userinfo } = await axios.get(`${API_BASE_URL}/user/info`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      });
      console.log("[Userinfo]", userinfo);
      if (userinfo.state) setUserInfo(userinfo.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect (() => {
    console.log("============ init loading ...")
    initLoading();
  })

  return (
    <div className="h-fit p-6 bg-black">
      <div className="flex flex-col items-center justify-between border-4 border-green-400 rounded-xl">
        <div className="w-full p-4 rounded-lg ">
          <div className="flex flex-col items-center mb-4">
            <img
              src="/images/mark.png"
              alt="Logo"
              className="w-32 h-32 mt-0 mb-0"
            />
            <div className="flex flex-row items-center ">
              <h1 className="mr-4 text-6xl text-yellow-400"> W </h1>
              <div>
                <h2 className="text-lg text-yellow-400"> YOUR WORLD </h2>
                <h2 className="text-3xl font-bold text-yellow-400">
                  {userInfo.balance + userInfo.invite_balance}
                </h2>
              </div>
            </div>
          </div>

          <div className="flex flex-col mb-2 gap-1">
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
                (window.location.href = "https://WWW.Prosperous.academy")
              }
            >
              ACADAMY
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
                (window.location.href = "https://t.me/+H5rEGY1DjWViMmZk")
              }
            >
              PRIME
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={showStakingDlg}
            >
              EARN
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
              (window.location.href =
                "https://ton.diamonds/dex/swap?ref=UQD65dACLUDwUpe4i8EfjVeXi9NqB8Ep-J5B6uO03qRUFxKY&inputToken=TON&outputToken=EQB6Ql1JE7Hq0JuropLYschcu-WNt2vMmWF2-8mEMrK68nJn")
              }
            >
              BUY WORLD
            </button>
          </div>
        </div>
        <Layout></Layout>
      </div>
    </div>
  );
}
