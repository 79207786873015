import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";
import { Button } from "@nextui-org/react";

function NavItem(props) {
  // const { pathname } = useLocation();
  // const active = pathname === props.path;

  return (
    <Button
      as={Link}
      to={props.path}
      variant="light"
      radius="none"
      className="flex flex-col items-center h-full gap-1 px-4 py-3 rounded-xl "
    >
      <img src={props.icon} alt="Button Image" className="w-11 h-11" />
      <p className="text-yellow-400">{props.name}</p>
    </Button>
  );
}

NavItem.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.string,
  activeIcon: PropTypes.string
};

export default NavItem;
